import {verifiedFetchJSON} from '@github-ui/verified-fetch'
import type {PresetPayload} from '../types'

export async function createPreset({preset}: {preset: PresetPayload}): Promise<Response> {
  const {conversationHistory, ...otherPresetParams} = preset

  const response = await verifiedFetchJSON(`/marketplace/models/presets`, {
    method: 'POST',
    body: {
      preset: {
        ...otherPresetParams,
        conversation_history: conversationHistory,
      },
    },
  })
  return response
}

export async function updatePreset({
  urlIdentifier,
  preset,
}: {
  urlIdentifier: string
  preset: PresetPayload
}): Promise<Response> {
  const encodedUrlIdentifier = encodeURIComponent(urlIdentifier)

  const {conversationHistory, ...otherPresetParams} = preset

  const response = await verifiedFetchJSON(`/marketplace/models/presets/${encodedUrlIdentifier}`, {
    method: 'PUT',
    body: {
      preset: {
        ...otherPresetParams,
        conversation_history: conversationHistory,
      },
    },
  })
  return response
}

export async function deletePreset({urlIdentifier}: {urlIdentifier: string}): Promise<Response> {
  const encodedUrlIdentifier = encodeURIComponent(urlIdentifier)
  const response = await verifiedFetchJSON(`/marketplace/models/presets/${encodedUrlIdentifier}`, {
    method: 'DELETE',
  })
  if (response.ok) {
    return response
  } else {
    throw new Error('Failed to delete preset')
  }
}
