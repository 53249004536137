import {useRef, useEffect, useState} from 'react'
import {PlaygroundAPIMessageAuthorValuesUser, type ModelState} from '../../../types'
import type {AzureModelClient} from '../../../utils/azure-model-client'

export function useExtractedPrompt(
  model: ModelState,
  modelClient: AzureModelClient,
  callback: (prompt: string) => void,
) {
  const [extractingPrompt, setExtractingPrompt] = useState(false)
  // We use useRef here so that React's behaviour of double-mounting components in the dev environment doesn't mean
  // we permanently have a race condition when a prompt needs extracting. It's not needed for production but should
  // be a noop.
  const hasExtractedPrompt = useRef(false)

  const extractPrompt = async (maybePrompt: string) => {
    if (hasExtractedPrompt.current) {
      return
    }
    hasExtractedPrompt.current = true
    setExtractingPrompt(true)

    const extractionPrompt =
      `Help me extract this LLM prompt from code. ` +
      `When given code, please respond ONLY with the text of the first prompt you see. ` +
      `Replace all templated variables with a guess as to the possible content, surrounded by curly braces (e.g. \${name}  becomes {John}, or \${quantity} becomes {30}). ` +
      `If nothing looks like a LLM prompt, use your imagination about how this code might be augmented into a prompt.\n` +
      `Please extract the prompt from this code: \n\`\`\`${maybePrompt}\n\`\`\``

    const extractionMessages = [
      {
        timestamp: new Date(),
        role: PlaygroundAPIMessageAuthorValuesUser,
        message: extractionPrompt,
      },
    ]

    const messageIterator = modelClient.sendMessage(0, model.catalogData, extractionMessages, {}, '', 'text')

    let extractedPrompt = ''
    for await (const response of messageIterator) {
      extractedPrompt = response.message.message as string
    }
    setExtractingPrompt(false)
    callback(extractedPrompt)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const maybePrompt = searchParams.get('mp')

    if (maybePrompt) {
      extractPrompt(maybePrompt)
    }
  }, [])

  return {extractingPrompt}
}
