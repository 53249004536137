import {Button} from '@primer/react'
import type {PlanInfo} from '../../types'

interface SetupButtonProps {
  planInfo: PlanInfo
}

export function SetupButton(props: SetupButtonProps) {
  const {planInfo} = props
  const hideButton = planInfo.viewer_has_purchased && planInfo.viewer_has_purchased_for_all_organizations

  return (
    !hideButton && (
      <Button
        as="a"
        variant="primary"
        className="js-smoothscroll-anchor width-full"
        href="#pricing-and-setup"
        data-testid="setup-button"
      >
        {planInfo.viewer_has_purchased || planInfo.any_orgs_purchased
          ? 'Add'
          : planInfo.plans.some(plan => plan.has_free_trial)
            ? 'Set up a free trial'
            : 'Add'}
      </Button>
    )
  )
}

try{ SetupButton.displayName ||= 'SetupButton' } catch {}