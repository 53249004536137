import {ActionList, ActionMenu, IconButton} from '@primer/react'
import {GearIcon, KebabHorizontalIcon, PencilIcon} from '@primer/octicons-react'
import type {AppListing} from '@github-ui/marketplace-common'
import type {PlanInfo} from '../../types'

interface HeaderActionMenuProps {
  app: AppListing
  planInfo: PlanInfo
  userCanEdit: boolean
}

export function HeaderActionMenu(props: HeaderActionMenuProps) {
  const {app, planInfo, userCanEdit} = props
  const showHeaderActionMenu =
    planInfo.viewer_has_purchased || planInfo.any_orgs_purchased || planInfo.installed_for_viewer || userCanEdit
  const anyPlansExist = planInfo.viewer_has_purchased || planInfo.any_orgs_purchased

  return (
    showHeaderActionMenu && (
      <div data-testid="listing-actions">
        <ActionMenu>
          <ActionMenu.Anchor>
            <IconButton icon={KebabHorizontalIcon} aria-label="More actions" data-testid="listing-actions-button" />
          </ActionMenu.Anchor>

          <ActionMenu.Overlay width="small" align="end">
            <ActionList>
              {anyPlansExist && (
                <ActionList.Group>
                  <ActionList.GroupHeading>Edit current plan</ActionList.GroupHeading>
                  {planInfo.viewer_has_purchased && planInfo.current_user && (
                    <ActionList.LinkItem
                      href={`/marketplace/${app.slug}/order/${
                        planInfo.plan_id_by_login[planInfo.current_user.display_login]
                      }?account=${planInfo.current_user.display_login}`}
                    >
                      {planInfo.current_user.display_login}
                    </ActionList.LinkItem>
                  )}
                  {planInfo.viewer_billed_organizations.map(orgName => (
                    <ActionList.LinkItem
                      key={orgName}
                      href={`/marketplace/${app.slug}/order/${planInfo.plan_id_by_login[orgName]}?account=${orgName}`}
                    >
                      {orgName}
                    </ActionList.LinkItem>
                  ))}
                </ActionList.Group>
              )}

              {anyPlansExist && (planInfo.installed_for_viewer || userCanEdit) && <ActionList.Divider />}

              {planInfo.installed_for_viewer && (
                <ActionList.LinkItem href="/settings/installations">
                  <ActionList.LeadingVisual>
                    <GearIcon />
                  </ActionList.LeadingVisual>
                  Configure account access
                </ActionList.LinkItem>
              )}
              {userCanEdit && (
                <ActionList.LinkItem href={`/marketplace/${app.slug}/edit`}>
                  <ActionList.LeadingVisual>
                    <PencilIcon />
                  </ActionList.LeadingVisual>
                  Manage app listing
                </ActionList.LinkItem>
              )}
            </ActionList>
          </ActionMenu.Overlay>
        </ActionMenu>
      </div>
    )
  )
}

try{ HeaderActionMenu.displayName ||= 'HeaderActionMenu' } catch {}