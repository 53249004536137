import type {Model} from '@github-ui/marketplace-common'
import {useNavigate} from '@github-ui/use-navigate'
import {SegmentedControl} from '@primer/react'
import {useState} from 'react'
import {useLocation, useSearchParams} from 'react-router-dom'
import {ModelUrlHelper} from '../../../utils/model-url-helper'
import {PlaygroundViewOption, playgroundViewSuffixes} from '../../playground/components/types'

export type ViewSwitcherProps = {
  model: Model
}

export function ViewSwitcher({model}: ViewSwitcherProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const [selectedOption, setSelectedOption] = useState<number>(() => {
    const finalPathComponent = location.pathname.split('/').pop()?.toLowerCase()

    switch (finalPathComponent) {
      case 'prompt':
        return PlaygroundViewOption.PROMPT
      case 'evals':
        return PlaygroundViewOption.EVALS
      default:
        return PlaygroundViewOption.CHAT
    }
  })

  const handleOptionChange = (option: PlaygroundViewOption) => {
    if (option === selectedOption) return
    setSelectedOption(option)
    const url = ModelUrlHelper.modelUrl(model)
    const suffix = playgroundViewSuffixes[option]

    navigate({
      pathname: `${url}${suffix}`,
      search: searchParams.toString(),
    })
  }

  const smallSizeForSegmentControl = {
    height: '28px',
    fontSize: 1,
  }

  return (
    <SegmentedControl aria-label="View mode" sx={smallSizeForSegmentControl} size="small" onChange={handleOptionChange}>
      <SegmentedControl.Button sx={smallSizeForSegmentControl} selected={selectedOption === PlaygroundViewOption.CHAT}>
        Chat
      </SegmentedControl.Button>
      <SegmentedControl.Button
        sx={smallSizeForSegmentControl}
        selected={selectedOption === PlaygroundViewOption.PROMPT}
      >
        Prompt
      </SegmentedControl.Button>
      <SegmentedControl.Button sx={smallSizeForSegmentControl} selected={selectedOption === PlaygroundViewOption.EVALS}>
        Evals
      </SegmentedControl.Button>
    </SegmentedControl>
  )
}

try{ ViewSwitcher.displayName ||= 'ViewSwitcher' } catch {}