import {sendEvent} from '@github-ui/hydro-analytics'
import type {Model} from '@github-ui/marketplace-common'
import {useNavigate} from '@github-ui/use-navigate'
import {CodeIcon, SquareFillIcon} from '@primer/octicons-react'
import {Box, Button, IconButton} from '@primer/react'
import {useCallback, useState, type FormEvent} from 'react'
import type {GettingStarted, ModelInputSchema} from '../../../types'
import {ModelUrlHelper} from '../../../utils/model-url-helper'
import {GettingStartedButtonClicked, templateRepositoryNwo} from '../../../utils/playground-types'
import GettingStartedDialog from '../../playground/components/GettingStartedDialog/GettingStartedDialog'
import {GiveFeedback} from '../../../components/GiveFeedback'
import ModelSwitcher from '../../playground/components/ModelSwitcher'
import {ViewSwitcher} from './ViewSwitcher'
import {useLocation} from 'react-router-dom'

export function Header({
  model,
  canUseO1Models,
  gettingStarted,
  running,
  canRun,
  run,
  stop,
}: {
  model: Model
  modelInputSchema: ModelInputSchema
  canUseO1Models: boolean
  gettingStarted: GettingStarted
  running: boolean
  /** Should run be enabled */
  canRun?: boolean
  run: () => void
  stop?(): void
}) {
  const openInCodespacesUrl = `/codespaces/new?template_repository=${templateRepositoryNwo}`
  const [isGettingStartedDialogOpen, setGettingStartedDialogOpen] = useState(false)

  const navigate = useNavigate()

  const handleRun = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault()

      run()
    },
    [run],
  )

  const handleStop = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault()

      if (stop) {
        stop()
      }
    },
    [stop],
  )

  const location = useLocation()

  const onModelSelect = useCallback(
    async (m: Model) => {
      const finalPathComponent = location.pathname.split('/').pop()?.toLowerCase()

      switch (finalPathComponent) {
        case 'prompt':
          navigate({
            pathname: ModelUrlHelper.promptUrl(m),
          })

          break
        case 'evals':
          navigate({
            pathname: ModelUrlHelper.evalsUrl(m),
          })
          break

        default:
          navigate({
            pathname: ModelUrlHelper.playgroundUrl(m),
          })
      }
    },
    [navigate, location],
  )

  return (
    <Box
      sx={{
        p: 0,
        pb: 3,
        borderColor: null,
        borderWidth: 0,
        borderStyle: null,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        display: 'flex',
        gap: 3,
        flexDirection: 'column',
        borderBottomWidth: 0,
      }}
    >
      <div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              minWidth: [0, 0, '320px'], // This is to avoid having different sizes for each playground on comparison view. As of shipping, the longest model name is about 320px.
              alignItems: 'center',
              width: 'auto',
            }}
          >
            <ModelSwitcher
              model={model}
              canUseO1Models={canUseO1Models}
              onSelect={onModelSelect}
              onComparisonMode={false}
              handleSetSidebarTab={() => {}}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ViewSwitcher model={model} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              width: 'auto',
              justifyContent: 'flex-end',
              flexGrow: 1,
              flexShrink: 0,
              flexDirection: 'row',
            }}
          >
            <Box sx={{display: ['none', 'none', 'flex']}}>
              <GiveFeedback />
            </Box>

            <>
              <IconButton
                sx={{display: ['flex', 'none', 'none']}}
                icon={CodeIcon}
                variant="primary"
                aria-label="Get API key"
                onClick={() => {
                  setGettingStartedDialogOpen(true)
                  sendEvent(GettingStartedButtonClicked, {
                    registry: model.registry,
                    model: model.name,
                    publisher: model.publisher,
                  })
                }}
              />
              <Button
                sx={{display: ['none', 'flex', 'flex']}}
                leadingVisual={CodeIcon}
                onClick={() => {
                  setGettingStartedDialogOpen(true)
                  sendEvent(GettingStartedButtonClicked, {
                    registry: model.registry,
                    model: model.name,
                    publisher: model.publisher,
                  })
                }}
              >
                Get API key
              </Button>
            </>

            {running ? (
              <Button leadingVisual={SquareFillIcon} variant="danger" onClick={handleStop}>
                Stop
              </Button>
            ) : (
              <Button variant="primary" onClick={handleRun} disabled={canRun === false}>
                Run
              </Button>
            )}

            {isGettingStartedDialogOpen && (
              <GettingStartedDialog
                openInCodespaceUrl={openInCodespacesUrl}
                onClose={() => setGettingStartedDialogOpen(false)}
                showCodespacesSuggestion
                gettingStarted={gettingStarted}
                modelName={model.name}
              />
            )}
          </Box>
        </Box>
      </div>
    </Box>
  )
}

try{ Header.displayName ||= 'Header' } catch {}