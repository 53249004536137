import {Dialog} from '@primer/react/experimental'

import {FormControl, Textarea} from '@primer/react'
import {testIdProps} from '@github-ui/test-id-props'
import type {ImprovePromptDialogState} from '../../../types'

interface ImprovePromptDialogProps {
  onClose: () => void
  setIsDialogOpen: (status: ImprovePromptDialogState) => void
  systemPrompt: string
  promptSuggestionText: string
  setPromptSuggestionText: (text: string) => void
}

export function ImprovePromptDialog({
  onClose,
  setIsDialogOpen,
  systemPrompt,
  promptSuggestionText,
  setPromptSuggestionText,
}: ImprovePromptDialogProps) {
  return (
    <Dialog
      width="large"
      onClose={onClose}
      title={<div {...testIdProps('prompt-dialog')}>Improve prompt</div>}
      footerButtons={[
        {
          buttonType: 'default',
          content: 'Cancel',
          onClick: onClose,
        },
        {
          buttonType: 'primary',
          content: 'Improve prompt',
          onClick: () => setIsDialogOpen('confirm'),
        },
      ]}
    >
      Adjust your prompt with specific suggestions or simply click to enhance your prompt.
      <div className="py-3 d-flex flex-column gap-3">
        <FormControl>
          <FormControl.Label>Current prompt</FormControl.Label>
          <Textarea value={systemPrompt} className="width-full" readOnly />
        </FormControl>
        <FormControl>
          <FormControl.Label>What would you like to improve?</FormControl.Label>
          <Textarea
            placeholder="Eg: explain X for a beginner and write responses in nested bullets."
            value={promptSuggestionText}
            onChange={e => setPromptSuggestionText(e.target.value)}
            className="width-full"
          />
        </FormControl>
      </div>
    </Dialog>
  )
}

try{ ImprovePromptDialog.displayName ||= 'ImprovePromptDialog' } catch {}