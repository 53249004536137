import {useCallback, useEffect, useState} from 'react'
import {PlaygroundAPIMessageAuthorValues} from '../../../types'
import type {AzureModelClient} from '../../../utils/azure-model-client'
import {Panel} from '../../../utils/playground-manager'
import {defaultResponseFormat} from '../../../utils/model-state'
import type {Model} from '@github-ui/marketplace-common'

export function useImprovePrompt(
  systemPrompt: string,
  promptSuggestionText: string,
  playgroundManagerClient: AzureModelClient,
  improvedSysPromptModel: Model,
) {
  const [generatedPrompt, setGeneratedPrompt] = useState(systemPrompt)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const generatePrompt = useCallback(async () => {
    try {
      const instruction = `Improve this prompt: \n${systemPrompt} ${
        promptSuggestionText ? `with the following instruction ${promptSuggestionText}` : ''
      }`
      const user = PlaygroundAPIMessageAuthorValues[0]

      const messages = [
        {
          timestamp: new Date(),
          role: user,
          message: instruction,
        },
      ]
      let response = ''
      for await (const res of playgroundManagerClient.sendMessage(
        Panel.Main,
        improvedSysPromptModel,
        messages,
        {},
        'You are an AI model designed to improve system prompts for other models. Please only respond with the improved system prompt.',
        defaultResponseFormat,
      )) {
        response = res.message.message as string
      }
      setGeneratedPrompt(response)
    } catch {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }, [improvedSysPromptModel, playgroundManagerClient, promptSuggestionText, systemPrompt])

  useEffect(() => {
    generatePrompt()
  }, [generatePrompt])

  return {generatedPrompt, isLoading, error}
}
