import {testIdProps} from '@github-ui/test-id-props'
import SidebarHeading from '@github-ui/marketplace-common/SidebarHeading'
import type {Labels} from '../../routes/playground/components/types'
import styles from './TagsSection.module.css'

interface TagsSectionProps {
  labels: Labels
  headingLevel?: 'h2' | 'h3'
}

export function TagsSection({labels: {tags, task}, headingLevel = 'h3'}: TagsSectionProps) {
  return (
    <div className={styles.tagsSection} {...testIdProps('tags-section')}>
      <SidebarHeading htmlTag={headingLevel} title="Tags" count={task ? tags.length + 1 : tags.length} />
      {(tags.length > 0 || task) && (
        <div className={styles.categories}>
          {tags.map(tag => {
            // categories cannot contain `-` characters, or ES wil analyze them as separate words
            const category = tag.toLowerCase().split('-')[0]

            return (
              <a href={`/marketplace?type=models&category=${category}`} key={tag} className="topic-tag topic-tag-link">
                {tag}
              </a>
            )
          })}

          {task && (
            <a href={`/marketplace?type=models&task=${task}`} key={task} className="topic-tag topic-tag-link">
              {task}
            </a>
          )}
        </div>
      )}
    </div>
  )
}

try{ TagsSection.displayName ||= 'TagsSection' } catch {}