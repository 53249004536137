import type {ActionListing} from '@github-ui/marketplace-common'
import {OverviewHeader} from '../OverviewHeader'
import {About} from './About'
import {Tags} from './Tags'
import type {Repository, ReleaseData, StarData} from '../../types'
import {DelistButton} from './DelistButton'
import {ReleaseBanner} from './ReleaseBanner'
import {Stack} from '@primer/react'
import {VersionButton} from './VersionButton'
import {StarButton} from './StarButton'

interface HeaderProps {
  action: ActionListing
  repository: Repository
  releaseData: ReleaseData
  repoAdminableByViewer: boolean
  loggedIn: boolean
  starData: StarData
}

export function Header(props: HeaderProps) {
  const {action, repository, repoAdminableByViewer, releaseData, loggedIn, starData} = props
  const {selectedRelease, latestRelease} = releaseData

  return (
    <OverviewHeader
      listing={action}
      banner={<ReleaseBanner selectedRelease={selectedRelease} latestRelease={latestRelease} action={action} />}
      delistButton={<DelistButton action={action} repoAdminableByViewer={repoAdminableByViewer} />}
      listingDetails={<About action={action} repository={repository} releaseData={releaseData} />}
      additionalDetails={<Tags tags={action.categories} />}
      callToAction={
        <Stack gap={'condensed'} direction={'horizontal'} className={'width-full width-md-auto'}>
          <StarButton repository={repository} action={action} loggedIn={loggedIn} starData={starData} />
          <VersionButton releaseData={releaseData} action={action} repository={repository} />
        </Stack>
      }
    />
  )
}

try{ Header.displayName ||= 'Header' } catch {}