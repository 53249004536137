import {Stack} from '@primer/react'
import {PlaygroundInput} from '../PlaygroundInput'
import {useCallback} from 'react'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import {usePlaygroundManager} from '../../../../utils/playground-manager'
import {usePlaygroundState} from '../../../../contexts/PlaygroundStateContext'
import {ModelResponseFormat} from '../ModelResponseFormat'
import IndexParameter from '../rag/IndexParameter'
import type {ModelInputChangeParams, ModelState, PlaygroundResponseFormat} from '../../../../types'
import {defaultResponseFormat, validateAndFilterParameters} from '../../../../utils/model-state'
import {testIdProps} from '@github-ui/test-id-props'
import {supportsStructuredOutput} from '../../../../utils/model-capability'
import {ModelSystemPrompt} from '../ModelSystemPrompt'

export default function ModelParameters({
  model,
  position,
  onSinglePlaygroundView,
}: {
  model: ModelState
  position: number
  onSinglePlaygroundView?: boolean
}) {
  const {modelInputSchema, isUseIndexSelected, parameters, responseFormat} = model

  const manager = usePlaygroundManager()
  const state = usePlaygroundState()
  const inSync = state.syncInputs
  const ragFeatureFlag = useFeatureFlag('project_neutron_rag')
  const structuredOutputsFeatureFlag = useFeatureFlag('project_neutron_structured_outputs')

  //  TODO: Models should specify whether they support RAG or not. For now, we'll hardcode it.
  const modelsSupportingRAG = [
    'Mistral-small',
    'Mistral-large',
    'Mistral-large-2407',
    'Mistral-Nemo',
    'Ministral-3B',
    'gpt-4o',
    'gpt-4o-mini',
    'Cohere-command-r',
    'Cohere-command-r-plus',
    'Cohere-command-r-08-2024',
    'Cohere-command-r-plus-08-2024',
  ]
  const isRAGSupported = modelsSupportingRAG.includes(model.catalogData.name)

  const isResponseFormatSupported = structuredOutputsFeatureFlag && supportsStructuredOutput(model.catalogData)

  const handleInputChange = ({key, value, validate}: ModelInputChangeParams) => {
    for (const [index, m] of state.models.entries()) {
      if (state.syncInputs || index === position) {
        const unvalidatedParams = {
          ...m.parameters,
          [key]: value,
        }
        const params = validate
          ? validateAndFilterParameters(modelInputSchema?.parameters || [], unvalidatedParams)
          : unvalidatedParams

        manager.setParameters(index, params)
        manager.setParametersHasChanges(index, true)
      }
    }
  }

  const handleSystemPromptChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      for (const [index] of state.models.entries()) {
        if (inSync || index === position) {
          manager.setSystemPrompt(index, event.target.value)
          manager.setParametersHasChanges(index, true)
        }
      }
    },
    [manager, position, state.models, inSync],
  )

  const updateSystemPrompt = useCallback(
    (systemPrompt: string) => {
      manager.setSystemPrompt(position, systemPrompt)
      manager.setParametersHasChanges(position, true)
    },
    [manager, position],
  )

  const handleResponseFormatChange = useCallback(
    (selectedResponseFormat: PlaygroundResponseFormat) => {
      for (const [index] of state.models.entries()) {
        if (inSync || index === position) {
          const newResponseFormat = selectedResponseFormat || defaultResponseFormat
          manager.setResponseFormat(index, newResponseFormat)
          manager.setParametersHasChanges(index, true)
        }
      }
    },
    [manager, position, state.models, inSync],
  )

  const handleIsUseIndexSelectedChange = useCallback(
    (isSelected: boolean) => {
      for (const [index] of state.models.entries()) {
        if (inSync || index === position) {
          manager.setIsUseIndexSelected(index, isSelected)
          manager.setParametersHasChanges(index, true)
        }
      }
    },
    [manager, position, state.models, inSync],
  )

  return (
    <Stack {...testIdProps('model-parameters')} gap="normal" className="p-0 p-md-3">
      {modelInputSchema?.parameters?.length === 0 &&
        !modelInputSchema?.capabilities?.systemPrompt &&
        !isRAGSupported && (
          <div className="p-3 d-flex flex-column flex-items-center">
            <span className="text-bold">No parameters available</span>
            <p>Currently, this model does not support any parameters for customization.</p>
          </div>
        )}
      {modelInputSchema?.capabilities?.systemPrompt && (
        <ModelSystemPrompt
          systemPrompt={model.systemPrompt}
          handleSystemPromptChange={handleSystemPromptChange}
          updateSystemPrompt={updateSystemPrompt}
          onSinglePlaygroundView={onSinglePlaygroundView}
        />
      )}

      {isResponseFormatSupported && (
        <ModelResponseFormat responseFormat={responseFormat} handleResponseFormatChange={handleResponseFormatChange} />
      )}

      {ragFeatureFlag && isRAGSupported && (
        <IndexParameter value={isUseIndexSelected} onChange={handleIsUseIndexSelectedChange} />
      )}
      {/* For each of a model’s inputs, display form components */}
      {(modelInputSchema?.parameters || []).map(parameter => {
        return (
          <PlaygroundInput
            key={parameter.key}
            value={parameters[parameter.key] ?? ''}
            parameter={parameter}
            handleInputChange={handleInputChange}
          />
        )
      })}
    </Stack>
  )
}

try{ ModelParameters.displayName ||= 'ModelParameters' } catch {}