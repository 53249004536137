import {ButtonGroup, Button} from '@primer/react'
import {TriangleDownIcon} from '@primer/octicons-react'
import {useState, useCallback} from 'react'
import {CodeSnippet} from './CodeSnippet'
import {VersionPicker} from './VersionPicker'
import {sendEvent} from '@github-ui/hydro-analytics'
import type {ReleaseData, Repository} from '../../types'
import type {ActionListing} from '@github-ui/marketplace-common'

interface VersionButtonProps {
  releaseData: ReleaseData
  action: ActionListing
  repository: Repository
}

export function VersionButton(props: VersionButtonProps) {
  const {releaseData, action, repository} = props
  const {selectedRelease, latestRelease, releases} = releaseData

  // Handle code snippet dialog open and close
  const [isCodeSnippetOpen, setIsCodeSnippetOpen] = useState(false)
  const onCodeSnippetClose = useCallback(() => setIsCodeSnippetOpen(false), [])

  // Handle version picker dialog open and close
  const [isVersionPickerOpen, setIsVersionPickerOpen] = useState(false)
  const onVersionPickerClose = useCallback(() => setIsVersionPickerOpen(false), [])

  return (
    <>
      <ButtonGroup className={'width-full'}>
        <Button
          variant={'primary'}
          onClick={() => {
            setIsCodeSnippetOpen(!isCodeSnippetOpen)
            sendEvent('marketplace.action.click', {
              repository_action_id: action.globalRelayId,
              source_url: `${window.location}`,
              location: 'actions#show',
            })
          }}
          className={'width-full'}
        >
          Use {selectedRelease ? selectedRelease.tagName : 'latest version'}
        </Button>
        <Button variant={'primary'} onClick={() => setIsVersionPickerOpen(!isVersionPickerOpen)}>
          <TriangleDownIcon size={16} aria-label={'Choose a version'} />
        </Button>
      </ButtonGroup>

      <CodeSnippet
        action={action}
        repository={repository}
        isOpen={isCodeSnippetOpen}
        onClose={onCodeSnippetClose}
        selectedRelease={selectedRelease}
        latestRelease={latestRelease}
      />

      <VersionPicker
        action={action}
        selectedRelease={selectedRelease}
        releases={releases}
        isOpen={isVersionPickerOpen}
        onClose={onVersionPickerClose}
      />
    </>
  )
}

try{ VersionButton.displayName ||= 'VersionButton' } catch {}