import {InlineAutocomplete} from '@github-ui/inline-autocomplete'
import type {ShowSuggestionsEvent, Suggestion} from '@github-ui/inline-autocomplete/types'
import {NoteIcon, PencilIcon, SlidersIcon, TrashIcon} from '@primer/octicons-react'
import {
  ActionList,
  ActionMenu,
  Button,
  FormControl,
  Heading,
  IconButton,
  PageLayout,
  Spinner,
  Textarea,
} from '@primer/react'
import {Blankslate} from '@primer/react/experimental'
import {useCallback, useState} from 'react'
import {GiveFeedback} from '../../../components/GiveFeedback'
import type {EvalsRow, ModelState} from '../../../types'
import type {AzureModelClient} from '../../../utils/azure-model-client'
import {Panel} from '../../../utils/playground-manager'
import ModelParameters from '../../playground/components/ModelParametersSidebar/ModelParameters'
import {PlaygroundChatMessage} from '../../playground/components/PlaygroundChatMessage'
import {usePromptEvalsState} from '../contexts/PromptEvalsStateContext'
import {referencedVariables, replaceVars} from '../evals-sdk/variables'
import {useExtractedPrompt} from '../hooks/use-extracted-prompt'
import {usePromptEvalsManager} from '../prompt-evals-manager'
import {Header} from './Header'
import styles from './Prompt.module.css'
import {VariablesDialog} from './VariablesDialog'

export type PromptProps = {
  modelState: ModelState
  modelClient: AzureModelClient
  canUseO1Models: boolean
}

export function Prompt({modelState, modelClient, canUseO1Models}: PromptProps) {
  const {gettingStarted, modelInputSchema, catalogData, messages, isLoading} = modelState
  const lastIndex = messages.length - 1

  const {prompt, systemPrompt, variables, evals} = usePromptEvalsState()
  const manager = usePromptEvalsManager()

  const [variableSuggestions, setVariableSuggestions] = useState<Suggestion[]>([])
  const onShowSuggestions = useCallback((_event: ShowSuggestionsEvent) => {
    // TODO: Get this from evals state, or separate Variables UI
    setVariableSuggestions(['{{input}}', '{{expected}}'])
  }, [])
  const onHideSuggestions = useCallback(() => {
    setVariableSuggestions([])
  }, [])

  const handlePromptInput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      manager.setPromptInput(event.target.value)
    },
    [manager],
  )

  const handleSystemPromptInput = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      manager.setSystemPrompt(event.target.value)
    },
    [manager],
  )

  const handleRun = useCallback(
    (vars: Record<string, string>) => {
      const usedVariables = referencedVariables(prompt)
      const variablesWithoutValue = usedVariables.filter(v => !vars[v])
      if (variablesWithoutValue.length > 0) {
        // One of the referenced variables doesn't have a value, ask the user for a value first
        setRequiredVariables(new Set(variablesWithoutValue))
        setShowRunVariablesDialog(true)
        return
      }

      // If this is a new variables combination, add as a data row
      if (!evals.rows.find(d => Object.keys(vars).every(v => d[v] === vars[v]))) {
        manager.evalsAddRow({
          id: evals.rows.length + 1,
          ...vars,
        } as EvalsRow)
      }

      const expandedPrompt = replaceVars(prompt, vars)
      manager.sendMessage(modelState, modelClient, systemPrompt, expandedPrompt)
    },
    [manager, modelState, modelClient, systemPrompt, prompt, evals.rows],
  )

  const handleStop = useCallback(() => {
    modelClient.stopStreamingMessages(Panel.Main)
  }, [modelClient])

  const handleExtractedPrompt = useCallback(() => {
    manager.setPromptInput(prompt)
    manager.sendMessage(modelState, modelClient, systemPrompt, prompt)
  }, [manager, modelClient, modelState, systemPrompt, prompt])
  const {extractingPrompt} = useExtractedPrompt(modelState, modelClient, handleExtractedPrompt)

  const [showEditVariablesDialog, setShowVariablesDialog] = useState(false)
  const handleCloseEditVariablesDialog = useCallback(
    (v?: Record<string, string>) => {
      if (v) {
        manager.setVariables(v)
      }
      setShowVariablesDialog(false)
    },
    [manager],
  )

  const [showRunVariablesDialog, setShowRunVariablesDialog] = useState(false)
  const handleCloseRunVariablesDialog = useCallback(
    (v?: Record<string, string>) => {
      setShowRunVariablesDialog(false)

      if (v) {
        // Persist variables
        manager.setVariables(v)

        // Reset required variables
        setRequiredVariables(undefined)

        handleRun(v)
      }
    },
    [manager, handleRun],
  )

  const [requiredVariables, setRequiredVariables] = useState<Set<string> | undefined>(undefined)

  const blankSlate = extractingPrompt ? (
    <Blankslate>
      <Blankslate.Visual>
        <Spinner size="medium" />
      </Blankslate.Visual>
      <Blankslate.Heading>Iterate on your prompt</Blankslate.Heading>
      <Blankslate.Description>Extracting the prompt from your code...</Blankslate.Description>
    </Blankslate>
  ) : (
    <Blankslate>
      <Blankslate.Visual>
        <NoteIcon size="medium" />
      </Blankslate.Visual>
      <Blankslate.Heading>Iterate on your prompt</Blankslate.Heading>
      <Blankslate.Description>Run prompt to see response from {modelState.catalogData.name}</Blankslate.Description>
    </Blankslate>
  )

  return (
    <div className={styles.promptContainer}>
      {showEditVariablesDialog && (
        <VariablesDialog primaryTitle="Save" variables={variables} onClose={handleCloseEditVariablesDialog} />
      )}
      {showRunVariablesDialog && (
        <VariablesDialog
          primaryTitle="Run"
          variables={variables}
          requiredVariables={requiredVariables}
          onClose={handleCloseRunVariablesDialog}
        />
      )}
      <div className={styles.mobileHeader}>
        <GiveFeedback mobile />
      </div>
      <div className={styles.promptWrapper}>
        <div className={styles.responsiveContainer}>
          <div className={styles.responsiveWrapper}>
            <Header
              run={() => handleRun(variables)}
              stop={handleStop}
              running={isLoading}
              canRun={!!prompt}
              model={catalogData}
              modelInputSchema={modelInputSchema}
              canUseO1Models={canUseO1Models}
              gettingStarted={gettingStarted}
            />
            <div className={`flex-1 d-flex flex-md-row border overflow-hidden rounded-2`}>
              <PageLayout padding="none" containerWidth="full" columnGap="none" className={styles.promptLayout}>
                <PageLayout.Pane resizable position="start" divider="none" padding="none">
                  <div className="d-flex flex-justify-between position-sticky top-0 p-2 border-bottom bgColor-muted flex-items-center">
                    <div className="flex-1" />
                    <Button
                      size="small"
                      leadingVisual={PencilIcon}
                      sx={{mr: 2}}
                      onClick={() => setShowVariablesDialog(true)}
                    >
                      Variables
                    </Button>
                    <ActionMenu
                      onOpenChange={(isOpen: boolean) => {
                        const {parameters: schema = []} = modelState.modelInputSchema || {}
                        if (isOpen || schema.length === 0 || !modelState.parametersHasChanges) return

                        // // Ensure validation when menu is closed, as it may not have occurred yet
                        // for (const [index, {modelInputSchema = {}, parameters}] of models.entries()) {
                        //   if (syncInputs || index === position) {
                        //     manager.setParameters(
                        //       index,
                        //       validateAndFilterParameters(modelInputSchema.parameters || [], parameters),
                        //     )
                        //   }
                        // }
                      }}
                    >
                      <ActionMenu.Anchor>
                        <IconButton icon={SlidersIcon} size="small" aria-label="Show parameters setting" />
                      </ActionMenu.Anchor>
                      <ActionMenu.Overlay width="xlarge" side="outside-left">
                        <ActionList>
                          <div
                            className="d-flex flex-items-center border-bottom borderColor-default px-3 py-2"
                            style={{minHeight: '49px'}} // This is to prevent the height from changing when the reset button appears
                          >
                            <Heading as="h1" className={styles.parametersHeading}>
                              Parameters
                            </Heading>
                          </div>
                          <ModelParameters model={modelState} position={Panel.Main} />
                        </ActionList>
                      </ActionMenu.Overlay>
                    </ActionMenu>
                    <IconButton
                      icon={TrashIcon}
                      size="small"
                      aria-label="Clear prompts and variables"
                      className="ml-2"
                      disabled={!systemPrompt && !prompt}
                      onClick={() => manager.evalsClearUserSystemPrompt()}
                    />
                  </div>

                  <form className="p-3">
                    <FormControl className="mb-3">
                      <FormControl.Label>System</FormControl.Label>
                      <Textarea
                        value={systemPrompt}
                        onChange={handleSystemPromptInput}
                        resize="vertical"
                        block
                        placeholder="You are a helpful assistant..."
                        rows={2}
                      />
                    </FormControl>

                    <FormControl className="mb-3">
                      <FormControl.Label>User</FormControl.Label>
                      <InlineAutocomplete
                        fullWidth
                        tabInsertsSuggestions
                        triggers={[
                          {
                            triggerChar: '{{',
                            keepTriggerCharOnCommit: false,
                          },
                        ]}
                        suggestions={variableSuggestions}
                        onShowSuggestions={onShowSuggestions}
                        onHideSuggestions={onHideSuggestions}
                      >
                        <Textarea
                          value={prompt}
                          onChange={handlePromptInput}
                          resize="vertical"
                          placeholder="Enter a user prompt. Add variables with {{variable}}"
                          block
                        />
                      </InlineAutocomplete>
                    </FormControl>
                  </form>
                </PageLayout.Pane>
                <PageLayout.Content as="div" className={styles.promptBody}>
                  {messages?.length > 0 ? (
                    <div className="position-absolute p-3">
                      {messages.map((message, index) => (
                        <PlaygroundChatMessage
                          model={modelState}
                          message={message}
                          index={index}
                          key={`${message.timestamp}-${message.message}`}
                          isLoading={index === lastIndex && isLoading}
                          isError={message.role === 'error'}
                          lastIndex={index === lastIndex}
                          handleRegenerate={() => {}}
                          handleClearHistory={() => {}}
                        />
                      ))}
                    </div>
                  ) : (
                    blankSlate
                  )}
                </PageLayout.Content>
              </PageLayout>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

try{ Prompt.displayName ||= 'Prompt' } catch {}