import {FormControl, Radio, RadioGroup} from '@primer/react'
import type {PlaygroundResponseFormat} from '../../../types'
import {testIdProps} from '@github-ui/test-id-props'

import styles from './ModelResponseFormat.module.css'

export function ModelResponseFormat({
  responseFormat,
  handleResponseFormatChange,
}: {
  responseFormat: PlaygroundResponseFormat
  handleResponseFormatChange: (selectedResponseFormat: PlaygroundResponseFormat) => void
}) {
  return (
    <>
      <div {...testIdProps('response-format')}>
        <RadioGroup
          name="Response formats"
          onChange={value => handleResponseFormatChange(value as PlaygroundResponseFormat)}
        >
          <RadioGroup.Label>Response format</RadioGroup.Label>
          <div className="d-flex gap-3">
            <FormControl>
              <Radio name="text-format" value="text" checked={responseFormat === 'text'} />
              <FormControl.Label>Text</FormControl.Label>
            </FormControl>
            <FormControl>
              <Radio name="json-object-format" value="json_object" checked={responseFormat === 'json_object'} />
              <FormControl.Label>JSON</FormControl.Label>
            </FormControl>
          </div>
          <div className={styles.caption}>Set the format for the model response.</div>
        </RadioGroup>
      </div>
    </>
  )
}

try{ ModelResponseFormat.displayName ||= 'ModelResponseFormat' } catch {}