import {Button, FormControl, Textarea} from '@primer/react'
import {useCallback, useRef, useState} from 'react'
import type {ImprovePromptDialogState, ShowModelPayload} from '../../../types'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {ImprovePromptDialog} from './ImprovePromptDialog'
import {ImprovePromptConfirmationDialog} from './ImprovePromptConfirmationDialog'
import {SparkleFillIcon} from '@primer/octicons-react'

export function ModelSystemPrompt({
  systemPrompt,
  handleSystemPromptChange,
  updateSystemPrompt,
  onSinglePlaygroundView,
}: {
  systemPrompt: string
  handleSystemPromptChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  updateSystemPrompt: (systemPrompt: string) => void
  onSinglePlaygroundView?: boolean
}) {
  const {improvedSysPromptModel} = useRoutePayload<ShowModelPayload>()

  const improvePromptFeatureFlag = useFeatureFlag('github_models_improve_prompt')

  const showButton = improvePromptFeatureFlag && onSinglePlaygroundView && improvedSysPromptModel

  const [isDialogOpen, setIsDialogOpen] = useState<ImprovePromptDialogState>('closed')

  const [promptSuggestionText, setPromptSuggestionText] = useState('')
  const [improvedPromptText, setImprovedPromptText] = useState('')

  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const onOpen = useCallback(() => {
    setIsDialogOpen('suggest')
  }, [])

  const onClose = useCallback(() => {
    setIsDialogOpen('closed')
  }, [])

  const handleUpdateSystemPrompt = useCallback(() => {
    updateSystemPrompt(improvedPromptText)
    setPromptSuggestionText('')
    setIsDialogOpen('closed')
  }, [improvedPromptText, updateSystemPrompt])

  return (
    <FormControl>
      <FormControl.Label>System prompt</FormControl.Label>
      <FormControl.Caption sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
        Set the context for the model response.{' '}
        {showButton && (
          <Button
            leadingVisual={SparkleFillIcon}
            onClick={onOpen}
            variant="invisible"
            className="text-bold"
            disabled={systemPrompt.length === 0}
          >
            Improve prompt
          </Button>
        )}
      </FormControl.Caption>
      <Textarea
        ref={textAreaRef}
        value={systemPrompt}
        block
        resize="vertical"
        rows={1}
        onChange={handleSystemPromptChange}
        name="systemPrompt"
        style={{height: 120}}
      />

      {showButton ? (
        <>
          {isDialogOpen === 'suggest' && (
            <ImprovePromptDialog
              onClose={onClose}
              setIsDialogOpen={setIsDialogOpen}
              systemPrompt={systemPrompt}
              promptSuggestionText={promptSuggestionText}
              setPromptSuggestionText={setPromptSuggestionText}
            />
          )}
          {isDialogOpen === 'confirm' && (
            <ImprovePromptConfirmationDialog
              onClose={onClose}
              handleUpdateSystemPrompt={handleUpdateSystemPrompt}
              promptSuggestionText={promptSuggestionText}
              systemPrompt={systemPrompt}
              setImprovedPromptText={setImprovedPromptText}
              improvedPromptText={improvedPromptText}
              improvedSysPromptModel={improvedSysPromptModel}
            />
          )}
        </>
      ) : null}
    </FormControl>
  )
}

try{ ModelSystemPrompt.displayName ||= 'ModelSystemPrompt' } catch {}