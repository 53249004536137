import type {EvaluatorCfg} from '../config'
import type {EvalOptions} from '../options'
import type {Evaluator} from './evaluator'
import {LLMEvaluator} from './llm'
import {StringEvaluator} from './string'

export async function getEvaluator(e: EvaluatorCfg, options: EvalOptions): Promise<Evaluator> {
  if (e.string) {
    return new StringEvaluator(e.name, e.string)
  }

  if (e.llm) {
    return new LLMEvaluator(e.name, e.llm, options)
  }

  throw new Error('Not implemented')
}
