import {ShowApp as LegacyShowApp} from '../components/legacy/ShowApp'
import {ListingLayout} from '../components/ListingLayout'
import {Header} from '../components/apps/Header'
import {Body} from '../components/apps/Body'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'
import type {ShowAppPayload} from '../types'
import {useRoutePayload} from '@github-ui/react-core/use-route-payload'

export function ShowApp() {
  const redesignFeatureFlag = useFeatureFlag('marketplace_layout_redesign')

  return <>{redesignFeatureFlag ? <RedesignedShowApp /> : <LegacyShowApp />}</>
}

const RedesignedShowApp = () => {
  const {listing, plan_info, user_can_edit, screenshots} = useRoutePayload<ShowAppPayload>()

  return (
    <ListingLayout
      header={<Header app={listing} planInfo={plan_info} userCanEdit={user_can_edit} />}
      body={<Body app={listing} screenshots={screenshots} />}
      sidebar={<div>Sidebar</div>}
    />
  )
}

try{ ShowApp.displayName ||= 'ShowApp' } catch {}
try{ RedesignedShowApp.displayName ||= 'RedesignedShowApp' } catch {}