import type {Model} from '@github-ui/marketplace-common'

export class ModelUrlHelper {
  static playgroundSuffix = 'playground'

  static modelUrl(model: Model) {
    return `/marketplace/models/${model.registry}/${model.name}`
  }

  static playgroundUrl(model: Model) {
    return `${ModelUrlHelper.modelUrl(model)}/${ModelUrlHelper.playgroundSuffix}`
  }

  static feedbackUrl(model: Model) {
    return `${ModelUrlHelper.modelUrl(model)}/feedback`
  }

  static promptUrl(model: Model) {
    return `${ModelUrlHelper.modelUrl(model)}/prompt`
  }

  static evalsUrl(model: Model) {
    return `${ModelUrlHelper.modelUrl(model)}/evals`
  }
}
