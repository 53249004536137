import {Link, Stack, Heading} from '@primer/react'
import styles from '../marketplace.module.css'

export const CopilotListingRequirement = () => {
  return (
    <Stack gap="normal">
      <Heading as="h3" variant="small">
        Requirements
      </Heading>
      <div className={styles['marketplace-content-container']}>
        <p className="m-0" data-testid="copilot-listing-requirement">
          Copilot Extensions require an active{' '}
          <Link inline href="https://github.com/features/copilot/plans">
            GitHub Copilot license
          </Link>
          .
        </p>
      </div>
    </Stack>
  )
}

try{ CopilotListingRequirement.displayName ||= 'CopilotListingRequirement' } catch {}