import {Box, Button} from '@primer/react'
import type {ModelState} from '../../../types'

interface AddModelButtonProps {
  models: ModelState[]
  onClick: () => void
}

export const AddModelButton = ({models, onClick}: AddModelButtonProps) => {
  const isSendingMessage = models.some(m => m.isLoading)
  return (
    <Box
      className="flex-justify-end"
      sx={{
        display: 'flex',
        maxWidth: '100%',
        minWidth: 0,
        alignItems: 'center',
        ml: 2,
      }}
    >
      <Button onClick={onClick} disabled={isSendingMessage} sx={{bg: 'canvas.default', color: 'fg.muted'}}>
        Compare
      </Button>
    </Box>
  )
}

try{ AddModelButton.displayName ||= 'AddModelButton' } catch {}