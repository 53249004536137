import {useCallback} from 'react'
import {Button} from '@primer/react'
import {XIcon} from '@primer/octicons-react'
import {marketplaceActionPath} from '@github-ui/paths'
import {useCSRFToken} from '@github-ui/use-csrf-token'
import {sendEvent} from '@github-ui/hydro-analytics'
import type {ActionListing} from '@github-ui/marketplace-common'

interface DelistButtonProps {
  action: ActionListing
  repoAdminableByViewer: boolean
}

export function DelistButton(props: DelistButtonProps) {
  const {action, repoAdminableByViewer} = props

  const authToken = useCSRFToken(marketplaceActionPath({slug: action.slug ?? ''}), 'delete')

  const delistDataAttrs = {
    'data-confirm':
      'Are you sure you want to delist this Action from the Marketplace? Note: This Action will still be installable as long as the repository is public.',
    'data-disable-with': 'Delisting...',
  }

  const handleDelistClick = useCallback(() => {
    sendEvent('marketplace.action.delist', {
      repository_action_id: action.globalRelayId,
      source_url: `${window.location}`,
      location: 'actions#show',
    })
  }, [action.globalRelayId])

  return (
    <>
      {repoAdminableByViewer && action.slug && (
        <form
          data-turbo="false"
          action={marketplaceActionPath({slug: action.slug})}
          method="post"
          data-testid="delist-form"
        >
          <input type="hidden" name="_method" value="delete" autoComplete="off" data-testid="hidden-delete" />
          <Button {...delistDataAttrs} onClick={handleDelistClick} type="submit" data-testid="delist-button">
            <XIcon size={16} className={'mr-1'} />
            Delist
          </Button>
          {
            // eslint-disable-next-line github/authenticity-token
            <input type="hidden" name="authenticity_token" value={authToken} data-testid="hidden-authenticity-token" />
          }
        </form>
      )}
    </>
  )
}

try{ DelistButton.displayName ||= 'DelistButton' } catch {}