import {Dialog, ActionList} from '@primer/react'
import {marketplaceActionPath} from '@github-ui/paths'
import type {Release} from '../../types'
import type {ActionListing} from '@github-ui/marketplace-common'

interface VersionPickerProps {
  action: ActionListing
  selectedRelease?: Release
  releases: Release[]
  isOpen: boolean
  onClose: () => void
}

export function VersionPicker(props: VersionPickerProps) {
  const {action, selectedRelease, releases, isOpen, onClose} = props

  return (
    <>
      {isOpen && (
        <Dialog title="Choose a version" onClose={onClose}>
          <ActionList showDividers variant="full" data-testid="version-picker-dialog">
            {releases.map(release => {
              const isActiveRelease = selectedRelease && release.tagName === selectedRelease.tagName
              return (
                <ActionList.LinkItem
                  key={release.tagName}
                  href={`${marketplaceActionPath({slug: action.slug || ''})}?version=${release.tagName}`}
                  active={isActiveRelease}
                  data-testid={isActiveRelease ? 'selected-version' : ''}
                >
                  {release.tagName}
                  {release.name && <ActionList.Description variant="block">{release.name}</ActionList.Description>}
                </ActionList.LinkItem>
              )
            })}
          </ActionList>
        </Dialog>
      )}
    </>
  )
}

try{ VersionPicker.displayName ||= 'VersionPicker' } catch {}