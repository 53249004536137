export const taskOptions = ['All', 'Chat-completion', 'Embeddings']
export const modelFamilyOptions = ['All providers', 'AI21 Labs', 'Cohere', 'Meta', 'Mistral AI', 'Microsoft', 'OpenAI']
export const categoryOptions = [
  {id: 'All', name: 'All'},
  {id: 'agents', name: 'Agents'},
  {id: 'conversation', name: 'Conversation'},
  {id: 'large context', name: 'Large context'},
  {id: 'low latency', name: 'Low latency'},
  {id: 'multilingual', name: 'Multilingual'},
  {id: 'multimodal', name: 'Multimodal'},
  {id: 'multipurpose', name: 'Multipurpose'},
  {id: 'rag', name: 'RAG'},
  {id: 'reasoning', name: 'Reasoning'},
  {id: 'understanding', name: 'Understanding'},
]
