import {createContext, useContext, type Dispatch} from 'react'
import type {AzureModelClient} from './azure-model-client'
import type {
  MessageContent,
  ModelDetails,
  ModelParameterValue,
  ModelState,
  PlaygroundMessage,
  PlaygroundResponseFormat,
  PlaygroundState,
  PlaygroundStateAction,
  TokenUsage,
} from '../types'

import {clearPlaygroundLocalStorage} from './playground-local-storage'
import {ModelClientError} from './playground-types'
import {verifiedFetchJSON} from '@github-ui/verified-fetch'
import {
  defaultResponseFormat,
  getModelState,
  combineParamsWithModel,
  validateAndFilterParameters,
  validateSystemPrompt,
} from './model-state'
import {createErrorMessage, getValidMessage} from './message-content-helper'
import {searchTool} from './rag-index-manager'
import {getDefaultTokenUsage, incrementTokenUsage, updateTokenUsage} from './model-usage'

export const PlaygroundManagerContext = createContext<PlaygroundManager>({} as PlaygroundManager)

export function usePlaygroundManager() {
  return useContext(PlaygroundManagerContext)
}

// This helper function allows us to encapsulate a lot of repetitive logic in a single function
const applyModelStatePayload = (
  state: PlaygroundState,
  {index, ...payload}: Partial<ModelState> & {index: number},
): PlaygroundState => ({
  ...state,
  models: state.models.map((modelState, i) => (i === index ? {...modelState, ...payload} : modelState)),
})

export function tasksReducer(state: PlaygroundState, {type, payload}: PlaygroundStateAction): PlaygroundState {
  switch (type) {
    /* These actions are all related to the modelState */
    case 'SET_IS_LOADING':
    case 'SET_MESSAGES':
    case 'SET_PARAMETERS':
    case 'SET_SYSTEM_PROMPT':
    case 'SET_IS_USE_INDEX_SELECTED':
    case 'SET_CHAT_CLOSED':
    case 'SET_CHAT_INPUT':
    case 'SET_PARAMETERS_HAS_CHANGES':
    case 'SET_RESPONSE_FORMAT':
    case 'SET_TOKEN_USAGE':
      return applyModelStatePayload(state, payload)
    case 'SET_MODEL_STATE': {
      // Update the model if the index exists, otherwise add it
      const {index, modelState} = payload
      return state.models[index]
        ? applyModelStatePayload(state, {index, ...modelState})
        : {...state, models: [...state.models, modelState]}
    }
    case 'REMOVE_MODEL':
      return {
        ...state,
        models: state.models.filter((_, index) => index !== payload.index),
      }
    /* These actions are related to the playground state */
    case 'SET_SYNC_INPUTS':
      return {...state, ...payload}
    default:
      return state
  }
}

export enum Panel {
  Main = 0,
  Side = 1,
}

export class PlaygroundManager {
  dispatch: Dispatch<PlaygroundStateAction>
  controller: AbortController | null = null

  constructor(dispatch: Dispatch<PlaygroundStateAction>) {
    this.dispatch = dispatch
  }

  updateMainModel(modelDetails: ModelDetails, currentModels: ModelState[], keepParameters: boolean) {
    const currentMainModel = currentModels[Panel.Main]
    // When a side model becomes the new main model (removing the main model), we want to persist the chat history and parameters always.
    const keepEverything = currentMainModel?.catalogData.name === modelDetails.catalogData.name
    const keepParams = keepParameters || keepEverything

    const newModel = combineParamsWithModel({
      modelDetails,
      systemPromptOverride: keepParams ? currentMainModel?.systemPrompt : undefined,
      responseFormatOverride: keepParams ? currentMainModel?.responseFormat : undefined,
      messagesOverride: keepEverything ? currentMainModel?.messages : undefined,
      parametersOverride: keepParams ? currentMainModel?.parameters : undefined,
      chatInputOverride: currentMainModel?.chatInput,
    })

    this.setModelState(Panel.Main, newModel)
  }

  setParameters(index: number, parameters: Record<string, ModelParameterValue>) {
    this.dispatch({type: 'SET_PARAMETERS', payload: {index, parameters}})
  }

  setChatInput(index: number, chatInput: MessageContent) {
    this.dispatch({type: 'SET_CHAT_INPUT', payload: {index, chatInput}})
  }

  setSystemPrompt(index: number, systemPrompt: string) {
    this.dispatch({type: 'SET_SYSTEM_PROMPT', payload: {index, systemPrompt}})
  }

  setResponseFormat(index: number, responseFormat: PlaygroundResponseFormat) {
    this.dispatch({type: 'SET_RESPONSE_FORMAT', payload: {index, responseFormat}})
  }

  setIsUseIndexSelected(index: number, isUseIndexSelected: boolean) {
    this.dispatch({type: 'SET_IS_USE_INDEX_SELECTED', payload: {index, isUseIndexSelected}})
  }

  setMessages(index: number, messages: PlaygroundMessage[]) {
    this.dispatch({type: 'SET_MESSAGES', payload: {index, messages}})
  }

  setIsLoading(index: number, isLoading: boolean) {
    this.dispatch({type: 'SET_IS_LOADING', payload: {index, isLoading}})
  }

  setChatClosed(index: number, chatClosed: boolean) {
    this.dispatch({type: 'SET_CHAT_CLOSED', payload: {index, chatClosed}})
  }

  setParametersHasChanges(index: number, parametersHasChanges: boolean) {
    this.dispatch({type: 'SET_PARAMETERS_HAS_CHANGES', payload: {index, parametersHasChanges}})
  }

  setTokenUsage(index: number, tokenUsage: TokenUsage) {
    this.dispatch({type: 'SET_TOKEN_USAGE', payload: {index, tokenUsage}})
  }

  resetHistory(index: number) {
    // For now we save messages for the main model only
    if (index === Panel.Main) {
      clearPlaygroundLocalStorage()
    }
    this.setMessages(index, [])
    this.setChatClosed(index, false)
    this.setTokenUsage(index, getDefaultTokenUsage())
  }

  async sendMessage(
    index: number,
    modelState: ModelState,
    modelClient: AzureModelClient,
    text: string,
    attachments: string[] = [],
  ): Promise<void> {
    const {
      parameters,
      modelInputSchema = {},
      systemPrompt,
      messages: currentMessages,
      catalogData,
      isUseIndexSelected,
    } = modelState

    const {parameters: schemaParameters = []} = modelInputSchema

    const responseFormat = modelState.catalogData.name.includes('o1')
      ? defaultResponseFormat
      : modelState.responseFormat || defaultResponseFormat

    // Validate all the inputs against the model schema
    const validParams = validateAndFilterParameters(schemaParameters, parameters)
    if (isUseIndexSelected) validParams.tools = [searchTool]
    const validPrompt = validateSystemPrompt(modelInputSchema, systemPrompt)
    const userMessage = getValidMessage(text, attachments, currentMessages, modelInputSchema, catalogData)

    if (!userMessage) return

    // Add the user message to the chat history
    const messages = [...currentMessages, userMessage]

    // Update the UI
    this.setMessages(index, messages)
    this.setIsLoading(index, true)

    try {
      // Send the message to the model
      const generator = modelClient.sendMessage(index, catalogData, messages, validParams, validPrompt, responseFormat)
      let response
      while (!(response = await generator.next()).done) {
        const lastMessage = messages[messages.length - 1]
        // If the last message is a placeholder, we want to remove it before adding the new message
        const removeLast = lastMessage?.role === 'assistant' && lastMessage?.message === ''

        this.setMessages(index, [...(removeLast ? messages.slice(0, -1) : messages), response.value.message])
        incrementTokenUsage(modelState)
      }
      updateTokenUsage(modelState, response.value)
    } catch (error: unknown) {
      // Some errors result in different UI states
      if (error instanceof ModelClientError) {
        this.setMessages(index, [...messages, createErrorMessage(error.message)])

        if (error?.canRetry) this.setChatInput(index, userMessage.message)
        if (error?.tokenLimitReached) this.setChatClosed(index, true)
      } else {
        this.setMessages(index, [...messages, createErrorMessage('An error occurred. Please try again.')])
      }
    }

    this.setIsLoading(index, false)
  }

  async getSideModel(modelName: string, currentModelState: ModelState, syncInputs = false) {
    if (this.controller) this.controller.abort() // Abort any previous requests
    this.controller = new AbortController()
    try {
      const res = await verifiedFetchJSON(`/marketplace/models/side_model?compare_to=${modelName}`, {
        signal: this.controller.signal,
      })
      if (!res.ok) throw new Error('Failed to fetch side model')
      const modelDetails = await res.json()
      const modelState = combineParamsWithModel({
        modelDetails,
        systemPromptOverride: syncInputs ? currentModelState.systemPrompt : undefined,
        responseFormatOverride: syncInputs ? currentModelState.responseFormat : undefined,
        parametersOverride: syncInputs ? currentModelState.parameters : undefined,
        chatInputOverride: syncInputs ? currentModelState.chatInput : undefined,
      })
      this.setModelState(Panel.Side, modelState)
      return {success: true}
    } catch {
      return {success: false}
    }
  }

  setModelState(index: number, modelState: ModelState) {
    this.dispatch({type: 'SET_MODEL_STATE', payload: {index, modelState}})
  }

  removeModel(index: number) {
    this.dispatch({type: 'REMOVE_MODEL', payload: {index}})
  }

  resetParamsAndSystemPrompt(index: number, modelDetails: ModelDetails) {
    const defaultModelState = getModelState(modelDetails)

    this.setParameters(index, defaultModelState.parameters)
    this.setSystemPrompt(index, defaultModelState.systemPrompt)
  }

  setSyncInputs(syncInputs: boolean) {
    this.dispatch({type: 'SET_SYNC_INPUTS', payload: {syncInputs}})
  }
}
