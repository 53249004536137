import type {Model} from '@github-ui/marketplace-common'

export function supportsStreaming(model: Model) {
  return model.name !== 'o1-mini' && model.name !== 'o1-preview'
}

export function supportsStructuredOutput(model: Model) {
  // currently o1-class models don't support structured outputs at all
  if (model.name.toLowerCase().includes('o1')) {
    return false
  }

  // all models 'support' JSON output, but some of them are not trained on enough JSON
  // to be able to actually do it without spinning forever and outputting \n tokens
  const publisher = model.publisher.toLowerCase()
  if (publisher === 'openai' || publisher === 'mistral ai') {
    return true
  }

  return false
}

export function supportsStreamingOptions(model: Model): boolean {
  return model.publisher.toLowerCase() === 'openai'
}
