import {Dialog, Button} from '@primer/react'
import {useState} from 'react'
import type {ModelState} from '../../../types'
import {usePlaygroundState} from '../../../contexts/PlaygroundStateContext'
import {ModelsAvatar} from '../../../components/ModelsAvatar'
import {testIdProps} from '@github-ui/test-id-props'
import {useFeatureFlag} from '@github-ui/react-core/use-feature-flag'

export function PlaygroundTokenUsageWidget({modelState}: {modelState: ModelState}) {
  const {lastMessageInputTokens, totalInputTokens, lastMessageOutputTokens, totalOutputTokens} = modelState.tokenUsage

  const [isOpen, setIsOpen] = useState(false)
  const {models: modelStates} = usePlaygroundState()

  const shouldRenderTokenUsage = useFeatureFlag('github_models_playground_token_usage')

  if (!shouldRenderTokenUsage) {
    return null
  }

  return (
    <>
      <div className="d-flex flex-items-center fgColor-muted text-small" {...testIdProps('playground-token-usage')}>
        <Button variant="invisible" size="small" aria-label="Show usage info" onClick={() => setIsOpen(!isOpen)}>
          <span className="text-bold">Tokens:</span> Input: {lastMessageInputTokens} / {totalInputTokens} Output:{' '}
          {lastMessageOutputTokens} / {totalOutputTokens}
        </Button>
      </div>
      {isOpen && (
        <Dialog title="Usage info" role="dialog" onClose={() => setIsOpen(false)}>
          <div className="d-flex gap-3">
            {modelStates.map((model, index) => (
              <PlaygroundTokenUsage key={`pg-usage-${index}`} modelState={model} />
            ))}
          </div>
        </Dialog>
      )}
    </>
  )
}

export function PlaygroundTokenUsage({modelState: {catalogData: model, tokenUsage: usage}}: {modelState: ModelState}) {
  return (
    <div className="d-flex flex-1 border rounded-2 p-3">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex gap-2">
          <ModelsAvatar model={model} />
          <span className="text-bold">{model.name}</span>
        </div>
        <div {...testIdProps('playground-usage-input-tokens')}>
          <div className="text-bold">Input Tokens</div>
          <ul className="ml-3 color-fg-muted">
            <li>Most recent message: {usage.lastMessageInputTokens}</li>
            <li>Total: {usage.totalInputTokens}</li>
          </ul>
        </div>
        <div {...testIdProps('playground-usage-output-tokens')}>
          <div className="text-bold">Output Tokens</div>
          <ul className="ml-3 color-fg-muted">
            <li>Most recent message: {usage.lastMessageOutputTokens}</li>
            <li>Total: {usage.totalOutputTokens}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

try{ PlaygroundTokenUsageWidget.displayName ||= 'PlaygroundTokenUsageWidget' } catch {}
try{ PlaygroundTokenUsage.displayName ||= 'PlaygroundTokenUsage' } catch {}