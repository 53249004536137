import {Stack, Link} from '@primer/react'
import SidebarHeading from '@github-ui/marketplace-common/SidebarHeading'
import {VerifiedIcon} from '@primer/octicons-react'

interface VerifiedOwnerProps {
  isVerifiedOwner: boolean
}

export function VerifiedOwner(props: VerifiedOwnerProps) {
  const {isVerifiedOwner} = props

  return (
    <>
      {isVerifiedOwner && (
        <Stack gap={'condensed'} data-testid={'verified-owner'}>
          <div className="d-flex flex-items-center gap-2">
            <SidebarHeading title="Verified" htmlTag={'h2'} />
            <VerifiedIcon size={16} className="fgColor-accent" />
          </div>
          <span>
            GitHub has manually verified the creator of the action as an official partner organization. For more info
            see{' '}
            <Link
              inline
              href="https://docs.github.com/en/actions/sharing-automations/creating-actions/publishing-actions-in-github-marketplace#about-badges-in-github-marketplace"
            >
              {'About badges in GitHub Marketplace'}
            </Link>
            .
          </span>
        </Stack>
      )}
    </>
  )
}

try{ VerifiedOwner.displayName ||= 'VerifiedOwner' } catch {}