import {createContext, useContext, type Dispatch} from 'react'
import type {
  EvalsRow,
  EvaluatorState,
  ModelDetails,
  ModelParameterValue,
  ModelState,
  PlaygroundMessage,
} from '../../types'
import type {AzureModelClient} from '../../utils/azure-model-client'
import {
  createErrorMessage,
  createSystemMessage,
  createUserMessage,
  getValidMessage,
} from '../../utils/message-content-helper'
import {
  combineParamsWithModel,
  defaultResponseFormat,
  getModelState,
  validateAndFilterParameters,
  validateSystemPrompt,
} from '../../utils/model-state'
import {Panel} from '../../utils/playground-manager'
import {ModelClientError} from '../../utils/playground-types'
import {searchTool} from '../../utils/rag-index-manager'
import {runEval} from './evals-sdk'
import type {Config, DataRow, EvaluatorCfg} from './evals-sdk/config'
import type {Result} from './evals-sdk/result'
import type {PromptEvalsState, PromptEvalsStateAction} from './prompt-evals-state'
import {setPromptEvalsLocalStorage} from '../../utils/prompt-evals-local-storage'
import type {Message} from './evals-sdk/api'
import type {Model} from '@github-ui/marketplace-common'

export const PromptEvalsManagerContext = createContext<PromptEvalsManager>({} as PromptEvalsManager)

export function usePromptEvalsManager() {
  return useContext(PromptEvalsManagerContext)
}

export function promptEvalsReducer(state: PromptEvalsState, action: PromptEvalsStateAction): PromptEvalsState {
  switch (action.type) {
    case 'SET_IS_LOADING':
      return {
        ...state,
        model: {...state.model, isLoading: action.payload.isLoading},
      }
    case 'SET_MESSAGES':
      return {
        ...state,
        model: {...state.model, messages: action.payload.messages},
      }
    case 'SET_PARAMETERS':
      return {
        ...state,
        model: {...state.model, parameters: action.payload.parameters},
      }
    case 'SET_SYSTEM_PROMPT': {
      const updatedSystemPrompt = {...state, systemPrompt: action.payload.systemPrompt}
      return returnAndSetLocalStorage(updatedSystemPrompt)
    }
    case 'SET_MODEL_STATE': {
      const {modelState} = action.payload

      return {
        ...state,
        model: modelState,
      }
    }
    case 'SET_PROMPT_INPUT': {
      return returnAndSetLocalStorage({...state, prompt: action.payload.prompt})
    }
    case 'CLEAR_PROMPTS_INPUT': {
      return returnAndSetLocalStorage({...state, prompt: '', systemPrompt: ''})
    }
    case 'EVAL_ADD_ROW': {
      const updatedRows = {
        ...state,
        evals: {...state.evals, rows: [...state.evals.rows, {...action.row, id: state.evals.rows.length} as EvalsRow]},
      }
      return returnAndSetLocalStorage(updatedRows)
    }
    case 'EVAL_UPDATE_ROW': {
      const updatedRows = {
        ...state,
        evals: {
          ...state.evals,
          rows: state.evals.rows.map(row => (row.id === action.row.id ? action.row : row)),
        },
      }
      return returnAndSetLocalStorage(updatedRows)
    }
    case 'EVAL_REMOVE_ROW': {
      const updatedRows = {
        ...state,
        evals: {
          ...state.evals,
          rows: state.evals.rows.filter(row => row.id !== action.id),
        },
      }
      return returnAndSetLocalStorage(updatedRows)
    }
    case 'EVAL_CLEAR': {
      const updatedEvals = {...state, evals: {rows: [], result: [], evaluators: [], isRunning: false}}
      return returnAndSetLocalStorage(updatedEvals)
    }
    case 'EVAL_ADD_EVALUATOR': {
      const updatedEvaluators = {
        ...state,
        evals: {...state.evals, evaluators: [...state.evals.evaluators, action.evaluator]},
      }
      return returnAndSetLocalStorage(updatedEvaluators)
    }
    case 'EVAL_UPDATE_EVALUATOR': {
      const updatedEvaluators = {
        ...state,
        evals: {
          ...state.evals,
          evaluators: state.evals.evaluators.map((evaluator, i) =>
            i === action.payload.index
              ? {
                  ...evaluator,
                  config: action.payload.evaluator,
                }
              : evaluator,
          ),
        },
      }
      return returnAndSetLocalStorage(updatedEvaluators)
    }
    case 'EVAL_REMOVE_EVALUATOR': {
      const updatedEvaluators = {
        ...state,
        evals: {
          ...state.evals,
          evaluators: state.evals.evaluators.filter((_, i) => i !== action.index),
          result: state.evals.result.map(row => ({...row, evals: row.evals.filter((_, i) => i !== action.index)})),
        },
      }
      return returnAndSetLocalStorage(updatedEvaluators)
    }
    case 'EVAL_SET_IS_RUNNING':
      return {
        ...state,
        evals: {
          ...state.evals,
          isRunning: action.payload.isRunning,
        },
      }
    case 'EVAL_SET_RESULT': {
      const updatedResult = {...state, evals: {...state.evals, result: action.result}}
      return returnAndSetLocalStorage(updatedResult)
    }
    case 'EVAL_UPDATE_RESULT_ROW': {
      let existingIndex = state.evals.result.indexOf(action.row)
      if (existingIndex === -1) {
        existingIndex = state.evals.result.length
      }
      return {
        ...state,
        evals: {
          ...state.evals,
          result: [
            ...state.evals.result.slice(0, existingIndex),
            action.row,
            ...state.evals.result.slice(existingIndex + 1),
          ],
        },
      }
    }
    case 'EVAL_SET_VARIABLES':
      return {
        ...state,
        variables: action.payload.variables,
      }
    case 'EVAL_SET_ERROR':
      return {
        ...state,
        error: action.payload.error,
      }
  }
}

function returnAndSetLocalStorage(state: PromptEvalsState): PromptEvalsState {
  setPromptEvalsLocalStorage(state)
  return state
}

export class PromptEvalsManager {
  dispatch: Dispatch<PromptEvalsStateAction>

  private evalsRunAbortController: AbortController | null = null

  constructor(dispatch: Dispatch<PromptEvalsStateAction>) {
    this.dispatch = dispatch
  }

  updateModel(modelDetails: ModelDetails, currentModel: ModelState, keepParameters: boolean) {
    const keepEverything = currentModel?.catalogData.name === modelDetails.catalogData.name
    const keepParams = keepParameters || keepEverything

    const newModel = combineParamsWithModel({
      modelDetails,
      systemPromptOverride: keepParams ? currentModel?.systemPrompt : undefined,
      responseFormatOverride: keepParams ? currentModel?.responseFormat : undefined,
      messagesOverride: keepEverything ? currentModel?.messages : undefined,
      parametersOverride: keepParams ? currentModel?.parameters : undefined,
      chatInputOverride: currentModel?.chatInput,
    })

    this.setModelState(newModel)
  }

  setParameters(parameters: Record<string, ModelParameterValue>) {
    this.dispatch({type: 'SET_PARAMETERS', payload: {parameters}})
  }

  setSystemPrompt(systemPrompt: string) {
    this.dispatch({type: 'SET_SYSTEM_PROMPT', payload: {systemPrompt}})
  }

  setMessages(messages: PlaygroundMessage[]) {
    this.dispatch({type: 'SET_MESSAGES', payload: {messages}})
  }

  setIsLoading(isLoading: boolean) {
    this.dispatch({type: 'SET_IS_LOADING', payload: {isLoading}})
  }

  setPromptInput(prompt: string) {
    this.dispatch({type: 'SET_PROMPT_INPUT', payload: {prompt}})
  }

  resetHistory() {
    this.setMessages([])
  }

  setError(error: string | undefined) {
    this.dispatch({type: 'EVAL_SET_ERROR', payload: {error}})
  }

  setVariables(variables: Record<string, string>) {
    this.dispatch({type: 'EVAL_SET_VARIABLES', payload: {variables}})
  }

  async startEvalsRun(
    model: ModelState,
    modelClient: AzureModelClient,
    models: Model[],
    prompt: string,
    rows: DataRow[],
    evaluators: EvaluatorCfg[],
  ): Promise<void> {
    // Stop any ongoing runs
    this.stopEvalsRun()
    this.evalsRunAbortController = new AbortController()

    this.dispatch({type: 'EVAL_SET_IS_RUNNING', payload: {isRunning: true}})

    const messages: Message[] = []

    const systemPrompt = validateSystemPrompt(model.modelInputSchema, model.systemPrompt)
    if (systemPrompt) {
      messages.push(createSystemMessage(systemPrompt) as Message)
    }

    messages.push(createUserMessage(prompt) as Message)

    const evalsConfig: Config = {
      models: [model.catalogData],
      prompts: [
        {
          messages,
        },
      ],
      datasets: [
        {
          rows,
        },
      ],
      evaluators,
    }

    // Clear result
    this.dispatch({type: 'EVAL_SET_RESULT', result: []})

    // Start evals run
    try {
      for await (const row of runEval(
        evalsConfig,
        {
          api: {
            sendMessages: async (evalModelId, evalModelParameters, evalMessages, s) => {
              const evalModel = models.find(m => m.id === evalModelId)
              if (!evalModel) {
                throw new Error(`Model ${evalModelId} not found`)
              }

              const response = await modelClient.sendNonStreamingMessage(
                evalModel,
                evalMessages,
                evalModelParameters as Record<string, unknown>,
                null, // sytem prompt
                'text',
                s,
              )

              return [response.message].map(x => ({
                timestamp: x.timestamp,
                role: x.role,
                message: x.message as string, // We aren't support multi-modal content for now
              }))
            },
          },
        },
        this.evalsRunAbortController.signal,
      )) {
        this.dispatch({type: 'EVAL_UPDATE_RESULT_ROW', row})
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)

      this.dispatch({type: 'EVAL_SET_ERROR', payload: {error: 'An error occurred while running evals.'}})
    } finally {
      this.dispatch({type: 'EVAL_SET_IS_RUNNING', payload: {isRunning: false}})
    }
  }

  stopEvalsRun() {
    // Cancel any ongoing run
    if (this.evalsRunAbortController) {
      this.evalsRunAbortController.abort()
      this.evalsRunAbortController = null
    }

    // Immediately flip the state back to not running
    this.dispatch({type: 'EVAL_SET_IS_RUNNING', payload: {isRunning: false}})
  }

  evalsAddRow(row: EvalsRow) {
    this.dispatch({type: 'EVAL_ADD_ROW', row})
  }

  evalsUpdateRow(row: EvalsRow) {
    this.dispatch({type: 'EVAL_UPDATE_ROW', row})
  }

  evalsRemoveRow(id: number) {
    this.dispatch({type: 'EVAL_REMOVE_ROW', id})
  }

  evalsClear() {
    this.dispatch({type: 'EVAL_CLEAR'})
  }

  evalsAddEvaluator(evaluator: EvaluatorState) {
    this.dispatch({type: 'EVAL_ADD_EVALUATOR', evaluator})
  }

  evalsUpdateEvaluator(index: number, evaluator: EvaluatorCfg) {
    this.dispatch({type: 'EVAL_UPDATE_EVALUATOR', payload: {index, evaluator}})
  }

  evalsRemoveEvaluator(index: number) {
    this.dispatch({type: 'EVAL_REMOVE_EVALUATOR', index})
  }

  evalsSetResult(result: Result) {
    this.dispatch({type: 'EVAL_SET_RESULT', result})
  }

  evalsClearUserSystemPrompt() {
    this.dispatch({type: 'CLEAR_PROMPTS_INPUT'})
  }

  async sendMessage(
    modelState: ModelState,
    modelClient: AzureModelClient,
    systemPrompt: string = '',
    text: string,
    attachments: string[] = [],
  ): Promise<void> {
    const {parameters, modelInputSchema = {}, messages: currentMessages, catalogData, isUseIndexSelected} = modelState

    const {parameters: schemaParameters = []} = modelInputSchema

    const responseFormat = modelState.catalogData.name.includes('o1')
      ? defaultResponseFormat
      : modelState.responseFormat || defaultResponseFormat

    // Validate all the inputs against the model schema
    const validParams = validateAndFilterParameters(schemaParameters, parameters)
    if (isUseIndexSelected) validParams.tools = [searchTool]
    const validPrompt = validateSystemPrompt(modelInputSchema, systemPrompt)
    const userMessage = getValidMessage(text, attachments, currentMessages, modelInputSchema, catalogData)

    if (!userMessage) return

    // For now always clear previous messages
    const messages = [userMessage]

    // Update the UI
    this.setMessages(messages)
    this.setIsLoading(true)

    try {
      // Send the message to the model
      for await (const response of modelClient.sendMessage(
        Panel.Main,
        catalogData,
        messages,
        validParams,
        validPrompt,
        responseFormat,
      )) {
        this.setMessages([...messages, response.message])
      }
    } catch (error: unknown) {
      // Some errors result in different UI states
      if (error instanceof ModelClientError) {
        this.setMessages([...messages, createErrorMessage(error.message)])

        // TODO: Error handling
        // if (error?.canRetry) this.setChatInput(index, userMessage.message)
        // if (error?.tokenLimitReached) this.setChatClosed(index, true)
      } else {
        this.setMessages([...messages, createErrorMessage('An error occurred. Please try again.')])
      }
    }

    this.setIsLoading(false)
  }

  setModelState(modelState: ModelState) {
    this.dispatch({type: 'SET_MODEL_STATE', payload: {modelState}})
  }

  resetParamsAndSystemPrompt(modelDetails: ModelDetails) {
    const defaultModelState = getModelState(modelDetails)

    this.setParameters(defaultModelState.parameters)
    this.setSystemPrompt(defaultModelState.systemPrompt)
  }
}
