import {UploadIcon} from '@primer/octicons-react'
import {Button} from '@primer/react'
import {useRef} from 'react'
import {usePromptEvalsManager} from '../prompt-evals-manager'
import {VariableExpected, VariableInput} from '../variables'
import type {EvalsRow} from '../../../types'

export type ImportRowsProps = {
  setShowImportError: (error?: string) => void
}

export function ImportRows({setShowImportError}: ImportRowsProps) {
  const manager = usePromptEvalsManager()
  const datasetInputRef = useRef<HTMLInputElement>(null)
  const ACCEPTED_MIME = ['.jsonl', 'text/jsonl', 'application/json-lines', 'application/jsonl']

  return (
    <>
      <input
        ref={datasetInputRef}
        type="file"
        accept={ACCEPTED_MIME.join(',')}
        multiple={false}
        hidden
        onChange={async e => {
          const file = e.target?.files && e.target.files[0]
          if (!file) {
            return
          }

          // Read file
          const content = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
              resolve(reader.result as string)
            }
            reader.onerror = reject
            reader.readAsText(file)
          })

          // Split content into lines
          const lines = content.split('\n')

          let error: string | undefined

          for (const line of lines) {
            try {
              const row = JSON.parse(line)

              // For now we are restricting this to a very small set of variables
              const supportedInputs = [VariableInput, VariableExpected]

              // Need at least one supported input
              if (!supportedInputs.some(input => input in row)) {
                // Report and skip this row
                error = 'Some rows were skipped. "input" or "expected" fields are required.'
                continue
              }

              // Only add supported inputs from row
              const evalsRow = {} as EvalsRow

              for (const input of supportedInputs) {
                if (row[input]) {
                  evalsRow[input] = `${row[input]}`
                }
              }

              manager.evalsAddRow(evalsRow)
            } catch {
              error = 'Error importing file'
            } finally {
              setShowImportError(error)
            }
          }

          // Reset file input
          e.target.value = ''
        }}
      />
      <Button
        size="small"
        leadingVisual={UploadIcon}
        onClick={e => {
          e.preventDefault()
          datasetInputRef.current?.click()
        }}
      >
        Import rows
      </Button>
    </>
  )
}

try{ ImportRows.displayName ||= 'ImportRows' } catch {}