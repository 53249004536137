import {PlaygroundChat} from './PlaygroundChat'
import {PlaygroundInputs} from './PlaygroundInputs'
import {useCallback, useEffect, useState} from 'react'
import {getDefaultUiState, setLocalStorageUiState} from '../../../utils/playground-local-storage'
import {Box} from '@primer/react'
import {PlaygroundHeader} from './PlaygroundHeader'
import {GiveFeedback} from '../../../components/GiveFeedback'
import {MessageHistoryProvider} from './MessageHistoryContext'
import ModelSwitcher from './ModelSwitcher'
import {useNavigate} from '@github-ui/use-navigate'
import {ModelUrlHelper} from '../../../utils/model-url-helper'
import {MarkGithubIcon} from '@primer/octicons-react'
import type {ModelState, SidebarSelectionOptions} from '../../../types'
import type {Model} from '@github-ui/marketplace-common'
import ModelItem from '../../index/components/ModelItem'
import commonStyles from '@github-ui/marketplace-common/marketplace-common.module.css'
import {useModelClient} from '../contexts/ModelClientContext'

export type PlaygroundProps = {
  modelState?: ModelState
  position: number
  onComparisonMode: boolean
  canUseO1Models: boolean
  featuredModels?: Model[]
}

export function Playground({modelState, position, onComparisonMode, canUseO1Models, featuredModels}: PlaygroundProps) {
  const storedDefaults = getDefaultUiState(modelState?.gettingStarted)
  const modelClient = useModelClient()
  const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false)
  const [uiState, setUiState] = useState(storedDefaults)

  const navigate = useNavigate()

  const handleSetSidebarTab = (value: SidebarSelectionOptions) => {
    setLocalStorageUiState({...uiState, sidebarTab: value})
    setUiState(prevState => ({...prevState, sidebarTab: value}))
    setShowSidebarOnMobile(true)
  }

  const handleShowSidebar = (value: boolean) => {
    setUiState(prevState => ({...prevState, showSidebar: value}))
    setLocalStorageUiState({...uiState, showSidebar: value})
  }

  const handleSelectLanguage = useCallback(
    (language: string) => {
      const languageEntry = modelState?.gettingStarted[language]
      if (!languageEntry) return
      const availableSDK = Object.keys(languageEntry.sdks).includes(uiState.preferredSdk)
        ? uiState.preferredSdk
        : Object.keys(languageEntry.sdks)[0] || ''
      setUiState(prevState => ({...prevState, preferredLanguage: language, preferredSdk: availableSDK}))
      setLocalStorageUiState({
        ...uiState,
        preferredLanguage: language,
        preferredSdk: availableSDK,
      })
    },
    [modelState?.gettingStarted, uiState],
  )

  const handleSelectSDK = useCallback(
    (sdk: string) => {
      setUiState(prevState => ({...prevState, preferredSdk: sdk}))
      setLocalStorageUiState({
        ...uiState,
        preferredSdk: sdk,
      })
    },
    [uiState],
  )

  const stopStreamingMessages = useCallback(() => modelClient.stopStreamingMessages(position), [modelClient, position])

  // Stop streaming messages when the model changes
  useEffect(() => {
    return stopStreamingMessages
  }, [stopStreamingMessages, modelState?.catalogData?.name])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100dvh - 64px)',
        minWidth: onComparisonMode ? '560px' : undefined, // This is required for Safari
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: ['block', 'block', 'none'],
        }}
      >
        <GiveFeedback mobile />
      </Box>
      <Box sx={{height: '100%', width: '100%', p: 3, overflow: 'auto'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              maxHeight: '100%',
              height: '100%',
            }}
          >
            {modelState ? (
              <PlaygroundHeader
                model={modelState?.catalogData}
                modelInputSchema={modelState?.modelInputSchema}
                canUseO1Models={canUseO1Models}
                position={position}
                gettingStarted={modelState?.gettingStarted}
                uiState={uiState}
                setUiState={setUiState}
                handleSetSidebarTab={handleSetSidebarTab}
              />
            ) : (
              <div className="d-flex flex-justify-between mb-3">
                <ModelSwitcher
                  canUseO1Models={canUseO1Models}
                  onSelect={async m => navigate({pathname: ModelUrlHelper.playgroundUrl(m)})}
                  onComparisonMode={onComparisonMode}
                  handleSetSidebarTab={handleSetSidebarTab}
                />
                <GiveFeedback />
              </div>
            )}
            <div
              className={`flex-1 d-flex flex-column flex-md-row border overflow-hidden ${
                onComparisonMode ? 'rounded-bottom-2' : 'rounded-2'
              }`}
            >
              {modelState ? (
                <>
                  <MessageHistoryProvider>
                    <PlaygroundChat
                      model={modelState}
                      position={position}
                      stopStreamingMessages={stopStreamingMessages}
                      showSidebar={uiState.showSidebar}
                      onComparisonMode={onComparisonMode}
                      handleSetSidebarTab={handleSetSidebarTab}
                      handleShowSidebar={handleShowSidebar}
                      uiState={uiState}
                      handleSelectLanguage={handleSelectLanguage}
                      handleSelectSDK={handleSelectSDK}
                    />
                  </MessageHistoryProvider>
                  {!onComparisonMode && (
                    <PlaygroundInputs
                      model={modelState}
                      position={position}
                      sidebarTab={uiState.sidebarTab}
                      showSidebar={uiState.showSidebar}
                      showSidebarOnMobile={showSidebarOnMobile}
                      handleSetSidebarTab={handleSetSidebarTab}
                      handleShowSidebar={handleShowSidebar}
                      handleShowSidebarOnMobile={setShowSidebarOnMobile}
                    />
                  )}
                </>
              ) : (
                <div className="flex-1 d-flex flex-column flex-justify-center flex-items-center">
                  <MarkGithubIcon size={64} className="mb-3" />
                  <div className="h3 pb-1">Welcome to GitHub Models</div>
                  <p className="color-fg-muted">
                    A catalog and playground of AI models to help you build AI features and products.
                  </p>
                  <p className="color-fg-muted">
                    Select a model to get started, or{' '}
                    <a className="Link--inTextBlock" href="/marketplace/models/catalog">
                      explore the full model catalog
                    </a>
                    .
                  </p>

                  <div className={`mt-8 mx-4 ${commonStyles['marketplace-featured-grid']}`}>
                    {featuredModels?.map(model => (
                      <ModelItem
                        key={model.id}
                        model={model}
                        href={`/marketplace/models/${model.registry}/${model.name}/playground`}
                        isFeatured
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

try{ Playground.displayName ||= 'Playground' } catch {}