import {SafeHTMLBox} from '@github-ui/safe-html'
import type {SafeHTMLString} from '@github-ui/safe-html'
import {Stack, Heading} from '@primer/react'
import Screenshots from '../../components/Screenshots'
import {CopilotListingRequirement} from '../../components/CopilotListingRequirement'
import type {AppListing} from '@github-ui/marketplace-common'
import type {Screenshot} from '../../types'
import styles from '../../marketplace.module.css'

interface BodyProps {
  app: AppListing
  screenshots: Screenshot[]
}

export function Body(props: BodyProps) {
  const {app, screenshots} = props

  return (
    <Stack gap="spacious">
      <div data-testid="markdown-body" className={styles['marketplace-content-container']}>
        <Stack gap="normal">
          <div className="markdown-body" data-testid="description">
            {app.fullDescription && (
              <SafeHTMLBox className="markdown-body" html={app.fullDescription as SafeHTMLString} />
            )}
            {app.extendedDescription && (
              <SafeHTMLBox className="markdown-body" html={app.extendedDescription as SafeHTMLString} />
            )}
          </div>
          <Screenshots screenshots={screenshots} />
        </Stack>
      </div>
      {app.copilotApp && <CopilotListingRequirement />}
      <Stack gap="normal" data-testid="pricing">
        <Heading as="h3" variant="small">
          Plans and pricing
        </Heading>
        <p>Some pricing stuff</p>
      </Stack>
    </Stack>
  )
}

try{ Body.displayName ||= 'Body' } catch {}