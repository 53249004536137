export enum PlaygroundContentOption {
  CHAT = 0,
  CODE = 1,
  JSON = 2,
}

export const playgroundContentSuffixes = {
  [PlaygroundContentOption.CHAT]: '',
  [PlaygroundContentOption.CODE]: '/code',
  [PlaygroundContentOption.JSON]: '/json',
}

export enum PlaygroundViewOption {
  CHAT = 0,
  PROMPT = 1,
  EVALS = 2,
}

export const playgroundViewSuffixes = {
  [PlaygroundViewOption.CHAT]: '/playground',
  [PlaygroundViewOption.PROMPT]: '/prompt',
  [PlaygroundViewOption.EVALS]: '/evals',
}

export type Labels = {
  task: string
  tags: string[]
}
