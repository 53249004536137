import type {ChatCompletionChunk, ModelState, TokenUsage, TokenUsageInfo} from '../types'

/**
 * Gets the default model token usage
 */
export const getDefaultTokenUsage = (): TokenUsage => ({
  lastMessageInputTokens: 0,
  totalInputTokens: 0,
  lastMessageOutputTokens: 0,
  totalOutputTokens: 0,
})

export function incrementTokenUsage(modelState: ModelState): void {
  modelState.tokenUsage.lastMessageOutputTokens++
}

export function updateTokenUsage(modelState: ModelState, tokenUsage?: TokenUsageInfo): void {
  if (!tokenUsage) return
  modelState.tokenUsage.lastMessageInputTokens = tokenUsage.inputTokens
  modelState.tokenUsage.totalInputTokens += tokenUsage.inputTokens
  modelState.tokenUsage.lastMessageOutputTokens = tokenUsage.outputTokens
  modelState.tokenUsage.totalOutputTokens += tokenUsage.outputTokens
}

export const tokenUsageFromChunk = (chunk: ChatCompletionChunk): TokenUsageInfo => {
  return {
    inputTokens: chunk.usage?.prompt_tokens || 0,
    outputTokens: chunk.usage?.completion_tokens || 0,
  }
}

export const tokenUsageFromResponse = ({
  prompt_tokens,
  completion_tokens,
}: {
  prompt_tokens: number
  completion_tokens: number
}): TokenUsageInfo => {
  return {
    inputTokens: prompt_tokens || 0,
    outputTokens: completion_tokens || 0,
  }
}
